// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// TRANSITION EFFECT
jQuery('.link-button a[href^="#"]').bind('click',function (e) {
    e.preventDefault();
    var $target = $($(e.currentTarget).attr('href')),
    offset = $target.data('scroll-offset') || 0;
    console.log ($(e.currentTarget).attr('href'));
    console.log ($target.offset().top, $('header#main-header').height());
    jQuery('html, body').stop().animate( {
        'scrollTop': $target.offset().top - $('header#main-header').height()
    }, 1200, 'swing', function () {
        window.location.hash = target;
    } );

} );

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
};

jQuery(function() {
  if($(".js-replace-img")) {
    replaceSrc();
  }
});

// Responsive menu
jQuery('#toggle').click(function() {
  $(this).toggleClass("cross");
  $('#primary_nav').toggleClass('open');
  $('body').toggleClass('overflow');
});

// Responsive sub-menu
jQuery('#sub-nav-open').click(function(e) {
  jQuery('.sub-nav').toggleClass('open');
});

// Hide/Show box search
jQuery('#search').click(function() {
  jQuery('#search-box').toggleClass('open-box');
});
jQuery('#close-search').click(function() {
  jQuery('#search-box').removeClass('close-box');
});

// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('#main-header').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('#main-header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('#main-header').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;
}

// NAVIGATION
// -- MAIN

$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    $("ul li ul").removeClass("open");
    $(this).next(".sub-menu").toggleClass("open");
});
